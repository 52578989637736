import { useMemo } from 'react';
import { useWindowSize } from '@react-hook/window-size';

export const usePeoplePatternArray = (itemCount) => {
    const [wWidth] = useWindowSize();
    const scrollBarWidth = 16;
    const getPeopleStyleModifier = (number) => {
        let patternSize;
        let normalisedNumber;
        switch (true) {
            // 4 Columns
            case (wWidth + scrollBarWidth) > 1250:
                patternSize = 8;
                normalisedNumber = (number % patternSize + patternSize) % patternSize;
                return [2, 4, 5, 7].includes(normalisedNumber + 1) ? 'below' : 'none';

            // 3 Columns
            case (wWidth + scrollBarWidth) > 700:
                patternSize = 6;
                normalisedNumber = (number % patternSize + patternSize) % patternSize;
                return [2, 4, 6].includes(normalisedNumber + 1) ? 'below' : 'none';

            // 2 Columns
            case (wWidth + scrollBarWidth) >= 435:
                patternSize = 4;
                normalisedNumber = (number % patternSize + patternSize) % patternSize;
                return [1, 4].includes(normalisedNumber + 1) ? 'below' : 'none';

            default:
                return 'none';
        }
    }
    return useMemo(() => {
        const pregeneratedStyles = [];
        const pregenerateCount = itemCount;
        for (let x = 0; x < pregenerateCount; x++) {
            pregeneratedStyles.push(getPeopleStyleModifier(x));
        }
        return pregeneratedStyles;
    }, [wWidth]);
}
