import React, { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';
import { useMediaQuery } from '@react-hook/media-query';
import { useSelector, useDispatch } from 'react-redux';
import { useBodyNoScroll } from '../../utils/common';
import FilterSelect from '../FilterSelect';
import PeopleCard from '../Cards/PeopleCard';
import CustomSelect from '../CustomSelect';
import { prepareGlobalFilterClasses } from '../../styles/globalFilterClasses';

import { getFilters, getPeoples, getPeoplesLoading } from '../../redux/people/people.selector';
import { addFilter, removeFilter, clearFilters, fetchPeoples, setFilters } from '../../redux/people/people.action';
import LottieAnimation from '../LottieAnimation';
import { loadingLottieJSON } from '../../utils/loadingLottieJSON';
import style from './PeopleListing.module.scss';
import { convertFiltersToObject, getVariableFromQueryString, addVariableToQuery } from '../../utils/queryString';
import InfiniteScroll from 'react-infinite-scroll-component';
import { usePeoplePatternArray } from '../../utils/masonryArrays';

const PeopleListing = ({ title, filters, peoples, copies, appliedFilters, hiddenFilters }) => {
    // Setup
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const selectedFilters = getFilters(state);
    const peoplesFromApi = getPeoples(state);
    const peoplesLoading = getPeoplesLoading(state);
    const [sort, setSort] = useState('a_z');
    const [peopleCache, setPeopleCache] = useState(peoples);
    const [page, setPage] = useState(0);
    const disclaimers = selectedFilters.map(filter => filter.disclaimer).filter(disclaimer => disclaimer);
    const searchFilter = selectedFilters.find(filter => filter.single) || { value: '' };

    if (hiddenFilters && hiddenFilters.length) {
        filters = filters.filter(filter => !hiddenFilters.includes(filter.type));
    }

    const sortSelect = [
        {
            label: copies.sortAZLabel,
            value: 'a_z',
        },
        {
            label: copies.sortZALabel,
            value: 'z_a',
        },
    ];

    // Behaviours
    const handleSearchChanged = e => {
        const type = e.target.name;
        const value = e.target.value;
        const label = value;
        const obj = { type: type, value: value, label: label, single: true };
        if (value === '') {
            dispatch(clearFilters(type));
        } else {
            dispatch(addFilter(obj));
        }

        setFilterUpdate(true);
    };

    const handleFilterSelect = e => {
        const type = e.currentTarget.name;
        const value = e.currentTarget.value;
        const label = e.currentTarget.getAttribute('data-label');
        const disclaimer = e.currentTarget.getAttribute('data-disclaimer');
        const obj = { type: type, value: value, label: label, disclaimer: disclaimer };
        if (value === 'all') {
            dispatch(clearFilters(type));
        } else {
            if (selectedFilters.length < 8) dispatch(addFilter(obj));
            else dispatch(removeFilter(obj));
        }

        setFilterUpdate(true);
    };

    const removeThisFilter = e => {
        const type = e.currentTarget.getAttribute('data-type');
        const value = e.currentTarget.getAttribute('data-value');
        const label = e.currentTarget.getAttribute('data-label');
        const obj = { type: type, value: value, label: label };
        dispatch(removeFilter(obj));

        setFilterUpdate(true);
    };

    const clearFiltersClick = () => {
        if (hiddenFilters && hiddenFilters.length) {
            const filtersToClear = filters.filter(f => !hiddenFilters.includes(f.type)).map(f => f.type);
            filtersToClear.push('keywords');
            dispatch(clearFilters(filtersToClear));
        } else {
            dispatch(clearFilters(''));
        }

        setFilterUpdate(true);
    };

    const handleSortChange = value => {
        const val = typeof value === 'string' ? value : value.target.value;
        if (val !== sort) {
            addVariableToQuery('sort', { sort: val }, '', router);
            setSort(val);
        }
    };

    useEffect(() => {
        const sortObject = getVariableFromQueryString('sort', '', router);

        if (sortObject && sortObject.length) {
            const sortValue = sortObject[0].value.replaceAll(' ', '_');
            if (sortValue && sort !== sortValue) {
                setTimeout(() => setSort(sortValue), 0);
            }
        }
    }, []);

    const updateFilters = () => {
        console.log({ selectedFilters });
        if (filterUpdate && JSON.stringify(filterCache) !== JSON.stringify(selectedFilters)) {
            setTimeout(() => setFilterCache(selectedFilters), 0);

            const unhiddenFilters = selectedFilters.filter(filter => !(hiddenFilters || []).includes(filter.type));
            if (selectedFilters.length) addVariableToQuery('filters', convertFiltersToObject(unhiddenFilters || []), '', router);
            setFilterUpdate(false);
        }
    }

    const historyBackFilterCheck = useRef(false);
    useEffect(() => {
        if (!historyBackFilterCheck.current && selectedFilters.length) {
            historyBackFilterCheck.current = true;
            updateFilters();
        }
    }, [selectedFilters]);

    const [filterUpdate, setFilterUpdate] = useState(true);
    useEffect(updateFilters, [selectedFilters]);

    const fetchData = () => {
        setPage(page + 1);
    };

    const convertFiltersToString = () => {
        const obj = {};
        selectedFilters.forEach(f => {
            if (f.single) {
                obj[f.type] = f.value;
            } else if (!obj[f.type]) {
                obj[f.type] = [f.value];
            } else {
                obj[f.type].push(f.value);
            }
        });
        return JSON.stringify(obj);
    };

    useEffect(() => {
        dispatch(fetchPeoples(page, convertFiltersToString(selectedFilters), sort, true));
    }, [page]);

    const [filterOpen, setFilterOpen] = useState(false);
    const filterClick = () => {
        setFilterOpen(true);
    };
    useBodyNoScroll(filterOpen);

    const isDesktop = useMediaQuery('only screen and (min-width: 768px)');
    useEffect(() => {
        if (isDesktop) {
            setFilterOpen(false);
        }
    }, [isDesktop]);

    const isFirstRun = useRef(true);
    const router = useRouter();

    /* // Removed as seemed unnecessary
    useEffect(() => {
        isFirstRun.current = true;
        return () => {
            dispatch(clearPeoples());
            dispatch(clearFilters(''));
        };
    }, [router.pathname]); */

    const [filterCache, setFilterCache] = useState(selectedFilters);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            const querystringFilters = getVariableFromQueryString('filters');
            const preSelectedFilters = [];
            if (appliedFilters) {
                for (let [type, value] of Object.entries(appliedFilters)) {
                    if (value.length > 0) {
                        let single = true;
                        if (isArray(value)) {
                            single = false;
                            value = value[0];
                        }
                        const obj = { type: type, value: value, label: value, single: single };
                        preSelectedFilters.push(obj);
                    }
                }
            }
            dispatch(setFilters([...querystringFilters, ...preSelectedFilters]));
            setTimeout(() => dispatch(fetchPeoples(0, convertFiltersToString(selectedFilters), sort)), 0); // Set a 0 timeout to throw it at the end of the stack
            return;
        }

        dispatch(fetchPeoples(0, convertFiltersToString(selectedFilters), sort));
        // setQueryStringValue('filters', convertFiltersToObject(selectedFilters || []), '', router);
    }, [filterCache]);

    useEffect(() => {
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, []);

    const handleRouteChange = url => {
        const savedHiddenFilters = selectedFilters.filter(sFilter => (hiddenFilters || []).includes(sFilter.type));
        const filters = getVariableFromQueryString('filters');
        const sort = getVariableFromQueryString('sort');

        if (filters && filters.length) {
            dispatch(setFilters([...filters, ...savedHiddenFilters]));
        } else {
            dispatch(setFilters([...savedHiddenFilters]));
        }

        if (sort && sort.length) {
            const sortValue = sort[0].value.replaceAll(' ', '_');
            setSort(sortValue);
        } else {
            setSort('a_z');
        }

        setPage(0);
        setTimeout(() => setFilterUpdate(true), 500);
    };

    const isFirstSort = useRef(true);

    useEffect(() => {
        if (isFirstSort.current) {
            isFirstSort.current = false;
            return;
        }
        dispatch(fetchPeoples(page, convertFiltersToString(selectedFilters), sort));
    }, [sort]);

    const startLoading = useRef(false);
    useEffect(() => {
        if (peoplesLoading) {
            startLoading.current = true;
        }
        if (
            startLoading.current &&
            !peoplesLoading &&
            peoplesFromApi.data !== undefined &&
            JSON.stringify(peoplesFromApi) !== JSON.stringify(peopleCache)
        ) {
            setPeopleCache(peoplesFromApi);
            startLoading.current = false;
        }
    }, [peoplesLoading]);

    const peoplePatternArray = usePeoplePatternArray(500);

    const RenderSelectedFilters = () => (
        <>
            {selectedFilters
                .filter(f => !(hiddenFilters || []).includes(f.type))
                .map((filter, i) => (
                    <button
                        key={i}
                        type='button'
                        data-type={filter.type}
                        data-label={filter.label}
                        data-value={filter.value}
                        className={style.filterButton}
                        onClick={removeThisFilter}
                    >
                        {filter.label}
                        <span className={style.cross} />
                    </button>
                ))}
            {disclaimers.length > 0 && (
                <div className={style.disclaimers}>
                    {disclaimers.map((disclaimer, i) => (
                        <div key={i} className={style.disclaimer}>
                            {disclaimer}
                        </div>
                    ))}
                </div>
            )}
        </>
    );

    return (
        <div className={style.el}>
            <div className={[style.container, 'container'].join(' ')}>
                <div className={style.filters}>
                    <div className={style.filtersMobile}>
                        {selectedFilters.filter(filter => !(hiddenFilters || []).includes(filter.type)).length > 0 ? (
                            <button type='button' className={style.clearButton} onClick={clearFiltersClick}>
                                <span className='icon-close' />
                                {copies?.clear}
                            </button>
                        ) : (
                            <div />
                        )}
                        <button type='button' className={style.filterButton} onClick={filterClick}>
                            <span className='icon-filter' />
                            {copies?.filter}
                        </button>
                    </div>
                    <div
                        className={
                            [style.filtersWrapper, filterOpen ? style.open : ''].join(' ') +
                            prepareGlobalFilterClasses(filters.length + 1, filters.length + 1, true)
                        }
                    >
                        <div className={style.filterHeadMobile}>
                            <button className={style.backBtn} onClick={() => setFilterOpen(false)}>
                                <span className='sr-only'>back</span>
                            </button>
                            <h5>{title}</h5>
                            <button className={style.closeBtn} onClick={() => setFilterOpen(false)}>
                                <span className='icon-close' />
                                <span className='sr-only'>close</span>
                            </button>
                        </div>
                        {selectedFilters.length > 0 && (
                            <div className={[style.selectedFilters, 'mobile'].join(' ')}>
                                <RenderSelectedFilters />
                            </div>
                        )}
                        <div className={style.filterSelectWrap}>
                            <input
                                value={searchFilter.value}
                                onChange={handleSearchChanged}
                                className={style.searchBox}
                                type='text'
                                name='keywords'
                                placeholder={copies?.keywordsSearch}
                            />
                        </div>
                        {filters.map(item => (
                            <div className={style.filterSelectWrap} key={item.type}>
                                <FilterSelect
                                    {...item}
                                    handleSelect={handleFilterSelect}
                                    selectedFilters={Array.from(selectedFilters).filter(f => f.type === item.type)}
                                />
                            </div>
                        ))}
                        <div className={style.filterBottomMobile}>
                            <button className={style.applyBtn} onClick={() => setFilterOpen(false)}>
                                {copies?.apply}
                            </button>
                        </div>
                    </div>
                </div>
                <div className={style.filtersSort + prepareGlobalFilterClasses(filters.length + 1, 1)}>
                    <div className={[style.selectedFilters, 'desktop'].join(' ')}>
                        {selectedFilters.length > 0 && <RenderSelectedFilters />}
                    </div>
                    <div className={[style.numberTeamMembersMobile, 'mobile'].join(' ')}>
                        {copies?.ofTeamMembers && (
                            <>
                                <span className={style.number}>{peopleCache.totalItems}</span> {copies?.ofTeamMembers}
                            </>
                        )}
                    </div>
                    <div className={style.sortSelect}>
                        <CustomSelect
                            active={sort}
                            data={sortSelect}
                            defaultTitle={copies?.sortBy}
                            colourVariant={'orange'}
                            handleSelect={handleSortChange}
                        />
                    </div>
                </div>
                {copies?.description !== null && copies?.description !== '' && (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: copies?.description,
                        }}
                    />
                )}
                <div className={[style.numberTeamMembers, 'desktop'].join(' ')}>
                    <div>
                        {copies?.ofTeamMembers && (
                            <>
                                <span className={style.number}>{peopleCache.totalItems}</span> {copies?.ofTeamMembers}
                            </>
                        )}
                    </div>
                    {selectedFilters.filter(filter => !(hiddenFilters || []).includes(filter.type)).length > 0 && (
                        <button
                            type='button'
                            className={[style.clearButton, 'desktop'].join(' ')}
                            onClick={clearFiltersClick}
                        >
                            <span className='icon-close' />
                            {copies?.clearFilters}
                        </button>
                    )}
                </div>
                <div className={style.peopleWrapper}>
                    <InfiniteScroll
                        className={style.masonryLayout}
                        dataLength={peopleCache.data.length}
                        next={fetchData}
                        hasMore={peopleCache.currentPage < peopleCache.totalPages}
                        loader={<div className={style.loading}><LottieAnimation json={loadingLottieJSON} loop={true} playImmediately={true} /></div>}
                        endMessage={<div></div>}
                    >
                        {peopleCache.data.length > 0 ? (
                            peopleCache.data.map((item, i) => (
                                <div key={`people${i}`} className={style.peopleCard}>
                                    <PeopleCard styleModifier={peoplePatternArray ? peoplePatternArray[i] : 'none'} {...item} selectedFilters={selectedFilters} />
                                </div>
                            ))
                        ) : (
                            <div className={style.noResults}>{copies?.noResultMessage}</div>
                        )}
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    );
};

PeopleListing.propTypes = {
    title: PropTypes.string,
    filters: PropTypes.array,
    peoples: PropTypes.object,
    copies: PropTypes.object,
    appliedFilters: PropTypes.object,
    hiddenFilters: PropTypes.array,
};

export default PeopleListing;
